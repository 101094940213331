import { useContext, useEffect, useState } from "react";
import { Card, Col, Row, DatePicker, Button, Spin } from "antd";
import dayjs from "dayjs";
import axios from "axios";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";

import {
  generateMonthsArray,
  getRealOrEstimateForMonthHeaderTitle,
  getFiscalYearForMonth,
} from "../services/utilities";
function SalesDashboard() {
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const [Dashboard, setDashboard] = useState({});
  const [YearBudgetDashboard, setYearBudgetDashboard] = useState([]);
  const [BudgetDashboard, setBudgetDashboard] = useState([]);
  const [RealDashboard, setRealDashboard] = useState([]);
  const [LabelDashboard, setLabelDashboard] = useState([]);
  const [ProductDashboard, setProductDashboard] = useState([]);
  const [RevenueRealDashboard, setRevenueRealDashboard] = useState([]);
  const [RevenuebudgetDashboard, setRevenuebudgetDashboard] = useState([]);
  const [RevenuerealDashboard, setRevenuerealDashboard] = useState([]);
  const [RevenueDashboard, setRevenueDashboard] = useState([]);
  const [RevenuerealDashboard1, setRevenuerealDashboard1] = useState([]);
  const [RevenueDashboard1, setRevenueDashboard1] = useState([]);
  const [RevenuerealDashboard2, setRevenuerealDashboard2] = useState([]);
  const [RevenueDashboard2, setRevenueDashboard2] = useState([]);
  const [RevenuerealDashboard3, setRevenuerealDashboard3] = useState([]);
  const [RevenueDashboard3, setRevenueDashboard3] = useState([]);
  const [RevenuerealDashboardtotal, setRevenuerealDashboardtotal] = useState(
    []
  );
  const [RevenueDashboardtotal, setRevenueDashboardtotal] = useState([]);
  const [startPeriod, setstartPeriod] = useState(1);
  const currentYear = new Date().getFullYear();
  //   const [startYear, setStartYear] = useState(currentYear - 1);
  //   const [endYear, setEndYear] = useState(currentYear);
  const [firstYear, setfirstYear] = useState(currentYear - 1);
  const [secondYear, setsecondYear] = useState(currentYear);
  const [loading, setLoading] = useState(false);

  const [salesBudgetsBudgetDashboard, SetSalesBudgetsBudgetDashboard] =
    useState([]);
  const [salesBudgetsRealDashboard, SetSalesBudgetsRealDashboard] = useState(
    []
  );
  const [salesBudgetsEstimatedDashboard, SetSalesBudgetsEstimatedDashboard] =
    useState([]);

  useEffect(() => {
    getDashboard(firstYear, secondYear);
    getStartPeriode();
  }, [Company.id, firstYear, secondYear]);

  const getStartPeriode = async () => {
    await axios
      .get(`${JSON_API}/Enterprises/main-info`)
      .then((res) => {
        const filteredData = res.data.filter((item) => item.id === Company.id);

        if (filteredData.length > 0) {
          setstartPeriod(filteredData[0].startPeriod);
        } else {
          // Handle case when no matching item is found
          setstartPeriod(1); // Or any other default value
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleYearChange = () => {
    if (firstYear && secondYear) {
      getDashboard(firstYear, secondYear); // Passe les années sélectionnées à la fonction
      setDashboard();
    }
  };
  const getDashboard = async (firstYear, secondYear) => {
    setLoading(true);
    const array = [];
    const arraybudget = [];
    const arrayreal = [];
    const arrayLabel = [];
    const arrayProduct = [];
    const arrayRevenueReal = [];
    const arrayrevenuebudget = [];
    const arrayrevenuereal = [];
    const arrayrevenue = [];
    const arrayrevenuereal1 = [];
    const arrayrevenue1 = [];
    const arrayrevenuereal2 = [];
    const arrayrevenue2 = [];
    const arrayrevenuereal3 = [];
    const arrayrevenue3 = [];
    await axios

      .get(
        `${JSON_API}/Orders/SalesDashboard/${Company.id}?firstYear=${firstYear}&secondYear=${secondYear}`
      )
      .then((res) => {
        setDashboard(res.data);
        console.log("DASHH", Dashboard);
        res.data.salesByProduct.map((e) => {
          arrayLabel.push(e.productLabel);
          arrayProduct.push(e.revenueTotal);
        });
        res.data.opportunitiesCountByStatus.map((e) => {
          arrayRevenueReal.push((e.open / 1000).toFixed(2));
          arrayRevenueReal.push((e.waiting / 1000).toFixed(2));
          arrayRevenueReal.push((e.suspended / 1000).toFixed(2));
        });

        var currentYear = new Date().getFullYear(); // Utiliser l'année actuelle
        var currentMonth = new Date().getMonth() + 1; // Utiliser le mois actuel (0-11, donc +1)
        const reorderedMonths = generateMonthsArray(Company.startPeriod);

        const reordeCurrentYearOrdersTotalsByMonth = [
          ...res.data.currentYearOrdersTotalsByMonth.slice(
            Company.startPeriod - 1
          ), // Commencez par le mois de la période de début
          ...res.data.currentYearOrdersTotalsByMonth.slice(
            0,
            Company.startPeriod - 1
          ), // Puis ajoutez les mois avant la période de début
        ];
        reordeCurrentYearOrdersTotalsByMonth.forEach((e, index) => {
          let fiscalYearOfMonth = getFiscalYearForMonth(
            reorderedMonths[index],
            Company.startPeriod,
            secondYear
          );
          let title = getRealOrEstimateForMonthHeaderTitle(
            fiscalYearOfMonth,
            currentYear,
            reorderedMonths[index],
            currentMonth
          );

          if (title === "Real") {
            arrayrevenuereal.push((e.total / 1000).toFixed(2));
            arrayrevenue.push(0);
          } else {
            arrayrevenue.push((e.total / 1000).toFixed(2));
            arrayrevenuereal.push(0);
          }
        });
        const reordePreviousYearOrdersTotalsByMonth = [
          ...res.data.previousYearOrdersTotalsByMonth.slice(
            Company.startPeriod - 1
          ), // Commencez par le mois de la période de début
          ...res.data.previousYearOrdersTotalsByMonth.slice(
            0,
            Company.startPeriod - 1
          ), // Puis ajoutez les mois avant la période de début
        ];
        reordePreviousYearOrdersTotalsByMonth.forEach((e, index) => {
          let fiscalYearOfMonth = getFiscalYearForMonth(
            reorderedMonths[index],
            Company.startPeriod,
            firstYear
          );
          let title = getRealOrEstimateForMonthHeaderTitle(
            fiscalYearOfMonth,
            currentYear,
            reorderedMonths[index],
            currentMonth
          );
          if (title === "Real") {
            arrayrevenuereal1.push((e.total / 1000).toFixed(2));
            arrayrevenue1.push(0);
          } else {
            arrayrevenue1.push((e.total / 1000).toFixed(2));
            arrayrevenuereal1.push(0);
          }
        });
        const reordeCurrentYearOpportunitiesTotalsByMonth = [
          ...res.data.currentYearOpportunitiesTotalsByMonth.slice(
            Company.startPeriod - 1
          ), // Commencez par le mois de la période de début
          ...res.data.currentYearOpportunitiesTotalsByMonth.slice(
            0,
            Company.startPeriod - 1
          ), // Puis ajoutez les mois avant la période de début
        ];
        console.log(
          "CurrentYearOpportunitiesTotalsByMonth",
          reordeCurrentYearOpportunitiesTotalsByMonth
        );

        reordeCurrentYearOpportunitiesTotalsByMonth.forEach((e, index) => {
          let fiscalYearOfMonth = getFiscalYearForMonth(
            reorderedMonths[index],
            Company.startPeriod,
            secondYear
          );
          let title = getRealOrEstimateForMonthHeaderTitle(
            fiscalYearOfMonth,
            currentYear,
            reorderedMonths[index],
            currentMonth
          );
          if (title === "Real") {
            arrayrevenuereal2.push((e.total / 1000).toFixed(2));
            arrayrevenue2.push(0);
          } else {
            arrayrevenue2.push((e.total / 1000).toFixed(2));
            arrayrevenuereal2.push(0);
          }
        });
        const reordePreviousYearOpportunitiesTotalsByMonth = [
          ...res.data.previousYearOpportunitiesTotalsByMonth.slice(
            Company.startPeriod - 1
          ), // Commencez par le mois de la période de début
          ...res.data.previousYearOpportunitiesTotalsByMonth.slice(
            0,
            Company.startPeriod - 1
          ), // Puis ajoutez les mois avant la période de début
        ];
        reordePreviousYearOpportunitiesTotalsByMonth.forEach((e, index) => {
          let fiscalYearOfMonth = getFiscalYearForMonth(
            reorderedMonths[index],
            Company.startPeriod,
            firstYear
          );
          let title = getRealOrEstimateForMonthHeaderTitle(
            fiscalYearOfMonth,
            currentYear,
            reorderedMonths[index],
            currentMonth
          );
          if (title === "Real") {
            arrayrevenuereal3.push((e.total / 1000).toFixed(2));
            arrayrevenue3.push(0);
          } else {
            arrayrevenue3.push((e.total / 1000).toFixed(2));
            arrayrevenuereal3.push(0);
          }
        });
        // Réorganiser les dépenses par mois en fonction de la période de début de l'entreprise
        const reordeSalesBudgets = [
          ...res.data.salesBudgetTotalsByMonth.slice(Company.startPeriod - 1), // Commencez par le mois de la période de début
          ...res.data.salesBudgetTotalsByMonth.slice(
            0,
            Company.startPeriod - 1
          ), // Puis ajoutez les mois avant la période de début
        ];
        var salesBudgetsBudgetArray = [];
        var salesBudgetsEstimatedArray = [];
        var salesBudgetsRealArray = [];
        reordeSalesBudgets.map((e, index) => {
          salesBudgetsBudgetArray.push((e.budget / 1000).toFixed(2));
          let fiscalYearOfMonth = getFiscalYearForMonth(
            reorderedMonths[index],
            Company.startPeriod,
            secondYear);

          let title = getRealOrEstimateForMonthHeaderTitle(
            fiscalYearOfMonth,
            currentYear,
            reorderedMonths[index],
            currentMonth
          );
          if (title === "Real") {
            salesBudgetsRealArray.push((e.real / 1000).toFixed(2));
            salesBudgetsEstimatedArray.push(0);
          } else {
            salesBudgetsEstimatedArray.push((e.real / 1000).toFixed(2));
            salesBudgetsRealArray.push(0);
          }
        });
        setLabelDashboard(arrayLabel);
        setProductDashboard(arrayProduct);
        setRevenueRealDashboard(arrayRevenueReal);
        setRevenuerealDashboard(arrayrevenuereal);
        SetSalesBudgetsBudgetDashboard(salesBudgetsBudgetArray);
        console.log("salesBudgetsBudgetArray", salesBudgetsBudgetArray);
        SetSalesBudgetsRealDashboard(salesBudgetsRealArray);
        SetSalesBudgetsEstimatedDashboard(salesBudgetsEstimatedArray);

        var arraytotal = [];
        var total = 0;
        arrayrevenuereal.forEach((e) => {
          arraytotal.push(parseFloat(e) + parseFloat(arrayrevenuereal2[total]));
          total = total + 1;
        });
        setRevenuerealDashboardtotal(arraytotal);

        total = 0;
        var arraytotal1 = [];
        arrayrevenue.forEach((e) => {
          arraytotal1.push(e + arrayrevenue2[total]);
          total = total + 1;
        });

        setRevenueDashboardtotal(arraytotal1);
        setRevenueDashboard(arrayrevenue);
        setRevenuerealDashboard1(arrayrevenuereal1);
        setRevenueDashboard1(arrayrevenue1);
        setRevenuerealDashboard2(arrayrevenuereal2);
        setRevenueDashboard2(arrayrevenue2);
        setRevenuerealDashboard3(arrayrevenuereal3);
        setRevenueDashboard3(arrayrevenue3);
        console.log(
          RevenueDashboard,
          RevenueRealDashboard,
          RevenuebudgetDashboard
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const months = [
    `${t("January")}`,
    `${t("February")}`,
    `${t("March")}`,
    `${t("April")}`,
    `${t("May")}`,
    `${t("June")}`,
    `${t("July")}`,
    `${t("August")}`,
    `${t("September")}`,
    `${t("October")}`,
    `${t("November")}`,
    `${t("December")}`,
  ];

  // Réorganiser les mois en fonction de la période de début
  const reorderedMonthsCategories = [
    ...months.slice(startPeriod - 1), // Commencez par le mois de la période de début
    ...months.slice(0, startPeriod - 1), // Puis ajoutez les mois avant la période de début
  ];
  const BudgetChart = {
    series: [
      {
        name: "values",
        data: RevenueRealDashboard,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [`${t("ouvert")}`, `${t("attente")}`, `${t("pendu")}`],
      },
      yaxis: {
        title: {
          text: `${t("thousands")}`,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              `${
                Company.currencies &&
                Company.currencies.length > 0 &&
                Company.currencies[0].shortLabel
                  ? Company.currencies[0].shortLabel
                  : "$"
              } ` +
              val +
              ` ${t("thousands")}`
            );
          },
        },
      },
    },
  };

  const ProductChart = {
    series: ProductDashboard,
    options: {
      chart: {
        width: "100%",
        type: "pie",
      },
      labels: LabelDashboard,
      legend: {
        position: "left",
        itemMargin: {
          horizontal: 5,
          vertical: 10,
        },
        formatter: function (val, opts) {
          const index = opts.seriesIndex;
          const percentage = (
            (opts.w.globals.series[index] /
              opts.w.globals.seriesTotals.reduce((a, b) => a + b, 0)) *
            100
          ).toFixed(2);
          return `${val} (${percentage}%)`;
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return val > 5 ? `${val.toFixed(2)}%` : "";
        },
        style: {
          fontSize: "10px",
          colors: ["#ffffff"],
        },
      },
      colors: [
        "#FF6633",
        "#FFB399",
        "#FF33FF",
        "#FFFF99",
        "#00B3E6",
        "#E6B333",
        "#3366E6",
        "#999966",
        "#99E6E6",
        "#66664D",
        "#B34D4D",
        "#80B300",
        "#809900",
        "#E6B3B3",
        "#6680B3",
        "#66991A",
        "#FF99E6",
        "#CCFF1A",
        "#FF1A66",
        "#E6331A",
        "#33FFCC",
        "#66994D",
        "#B366CC",
        "#4D8000",
        "#B33300",
        "#CC80CC",
        "#6666FF",
        "#991AFF",
        "#E666FF",
        "#4DB3FF",
        "#1AB399",
        "#E666B3",
        "#33991A",
        "#CC9999",
        "#B3B31A",
        "#00E680",
        "#4D8066",
        "#809980",
        "#E6FF80",
        "#1AFF33",
        "#999933",
        "#FF3380",
        "#CCCC00",
        "#66E64D",
        "#4D80CC",
        "#9900B3",
        "#E64D66",
        "#4DB380",
        "#FF4D4D",
        "#99E6E6",
        "#6666FF",
      ],
      responsive: [
        {
          breakpoint: 500,
          options: {
            chart: {
              width: 400,
            },
          },
        },
      ],
    },
  };
  const RealChart = {
    series: [
      {
        name: `${t("Real")}`,
        data: RevenuerealDashboard,
      },
      {
        name: `${t("Estimate")}`,
        data: RevenueDashboard,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: reorderedMonthsCategories,
      },
      yaxis: {
        title: {
          text: `${t("thousands")}`,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              `${
                Company.currencies &&
                Company.currencies.length > 0 &&
                Company.currencies[0].shortLabel
                  ? Company.currencies[0].shortLabel
                  : "$"
              } ` +
              val +
              ` ${t("thousands")}`
            );
          },
        },
      },
    },
  };
  const RealChart1 = {
    series: [
      {
        name: `${t("Real")}`,
        data: RevenuerealDashboard1,
      },
      {
        name: `${t("Estimate")}`,
        data: RevenueDashboard1,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: reorderedMonthsCategories,
      },
      yaxis: {
        title: {
          text: `${t("thousands")}`,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              `${
                Company.currencies &&
                Company.currencies.length > 0 &&
                Company.currencies[0].shortLabel
                  ? Company.currencies[0].shortLabel
                  : "$"
              } ` +
              val +
              ` ${t("thousands")}`
            );
          },
        },
      },
    },
  };
  const Chart = {
    series: [
      {
        name: `${t("Real")}`,
        data: RevenuerealDashboard2,
      },
      {
        name: `${t("Estimate")}`,
        data: RevenueDashboard2,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: reorderedMonthsCategories,
      },
      yaxis: {
        title: {
          text: `${t("thousands")}`,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              `${
                Company.currencies &&
                Company.currencies.length > 0 &&
                Company.currencies[0].shortLabel
                  ? Company.currencies[0].shortLabel
                  : "$"
              } ` +
              val +
              ` ${t("thousands")}`
            );
          },
        },
      },
    },
  };
  const Chart1 = {
    series: [
      {
        name: `${t("Real")}`,
        data: RevenuerealDashboard3,
      },
      {
        name: `${t("Estimate")}`,
        data: RevenueDashboard3,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: reorderedMonthsCategories,
      },
      yaxis: {
        title: {
          text: `${t("thousands")}`,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              `${
                Company.currencies &&
                Company.currencies.length > 0 &&
                Company.currencies[0].shortLabel
                  ? Company.currencies[0].shortLabel
                  : "$"
              } ` +
              val +
              ` ${t("thousands")}`
            );
          },
        },
      },
    },
  };

  const Charttotal = {
    series: [
      {
        name: `${t("Real")}`,
        data: RevenuerealDashboardtotal,
      },
      {
        name: `${t("Estimate")}`,
        data: RevenueDashboardtotal,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: reorderedMonthsCategories,
      },
      yaxis: {
        title: {
          text: `${t("thousands")}`,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              `${
                Company.currencies &&
                Company.currencies.length > 0 &&
                Company.currencies[0].shortLabel
                  ? Company.currencies[0].shortLabel
                  : "$"
              } ` +
              val +
              ` ${t("thousands")}`
            );
          },
        },
      },
    },
  };
  const SalesBudgetChart = {
    series: [
      {
        name: `${t("Budget")}`,
        data: salesBudgetsBudgetDashboard,
      },
      {
        name: `${t("Real")}`,
        data: salesBudgetsRealDashboard,
      },
      {
        name: `${t("Estimate")}`,
        data: salesBudgetsEstimatedDashboard,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: reorderedMonthsCategories,
      },
      yaxis: {
        title: {
          text: `${t("thousands")}`,
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return (
              `${
                Company.currencies &&
                Company.currencies.length > 0 &&
                Company.currencies[0].shortLabel
                  ? Company.currencies[0].shortLabel
                  : "$"
              } ` +
              val +
              ` ${t("thousands")}`
            );
          },
        },
      },
    },
  };
  return (
    <>
      <div className="layout-content">
        <Card
          title={"Options"}
          style={{ marginBottom: "10px" }}
          justify="center" // Centrer les colonnes
          align="middle"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center", // Centre horizontalement
              alignItems: "center", // Centre verticalement
              gap: "20px", // Espacement entre les DatePicker
            }}
          >
            <DatePicker
              picker="year"
              placeholder={t("Start Year")}
              onChange={(date, dateString) => {
                if (date) {
                  setfirstYear(date.year());
                }
              }}
              defaultValue={dayjs().subtract(1, "year")}
              style={{ width: "350px", height: "40px", fontSize: "16px" }}
            />
            <DatePicker
              picker="year"
              placeholder={t("End Year")}
              onChange={(date, dateString) => {
                if (date) {
                  setsecondYear(date.year());
                }
              }}
              defaultValue={dayjs()}
              style={{ width: "350px", height: "40px", fontSize: "16px" }}
            />
          </div>
        </Card>

        <Row gutter={[8, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h3 style={{ textAlign: "center", marginBottom: 59 }}>
                {t("Vente") + " " + secondYear}
              </h3>

              {loading ? (
                <Spin style={{ display: "block", textAlign: "center" }} />
              ) : (
                <ReactApexChart
                  options={ProductChart.options}
                  series={ProductChart.series}
                  type="pie"
                />
              )}
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h3 style={{ textAlign: "center" }}>
                {t("Totaldesventesbystatus") + " " + secondYear}
              </h3>
              {loading ? (
                <Spin style={{ display: "block", textAlign: "center" }} />
              ) : (
                <ReactApexChart
                  options={BudgetChart.options}
                  series={BudgetChart.series}
                  type="bar"
                  height={250}
                />
              )}
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h3 style={{ textAlign: "center" }}>
                {t("salesBudgetByMonth") + " " + secondYear}
              </h3>
              {loading ? (
                <Spin style={{ display: "block", textAlign: "center" }} />
              ) : (
                <ReactApexChart
                  options={SalesBudgetChart.options}
                  series={SalesBudgetChart.series}
                  type="bar"
                  height={380}
                />
              )}
            </Card>
          </Col>
        </Row>
        {/* Total des ventes dans le carnet de commande - 2024 */}
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h3 style={{ textAlign: "center" }}>
                {t("Totaldesventes") + " " + secondYear}
              </h3>
              {loading ? (
                <Spin style={{ display: "block", textAlign: "center" }} />
              ) : (
                <ReactApexChart
                  options={RealChart.options}
                  series={RealChart.series}
                  type="bar"
                  height={380}
                />
              )}
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h3 style={{ textAlign: "center" }}>
                {t("Totaldesventes1") + " " + firstYear}
              </h3>
              {loading ? (
                <Spin style={{ display: "block", textAlign: "center" }} />
              ) : (
                <ReactApexChart
                  options={RealChart1.options}
                  series={RealChart1.series}
                  type="bar"
                  height={380}
                />
              )}
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h3 style={{ textAlign: "center" }}>
                {t("Totaldesventes2") + " " + secondYear}
              </h3>
              {loading ? (
                <Spin style={{ display: "block", textAlign: "center" }} />
              ) : (
                <ReactApexChart
                  options={Chart.options}
                  series={Chart.series}
                  type="bar"
                  height={380}
                />
              )}
            </Card>
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h3 style={{ textAlign: "center" }}>
                {t("Totaldesventes3") + " " + firstYear}
              </h3>
              {loading ? (
                <Spin style={{ display: "block", textAlign: "center" }} />
              ) : (
                <ReactApexChart
                  options={Chart1.options}
                  series={Chart1.series}
                  type="bar"
                  height={380}
                />
              )}
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <h3 style={{ textAlign: "center" }}>
                {t("Totaldesventestotal") + secondYear}
              </h3>
              {loading ? (
                <Spin style={{ display: "block", textAlign: "center" }} />
              ) : (
                <ReactApexChart
                  options={Charttotal.options}
                  series={Charttotal.series}
                  type="bar"
                  height={380}
                />
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

SalesDashboard.acl = {
  subject: "Dashboard",
  action: "read",
};

export default SalesDashboard;
