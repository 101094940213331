import {
  DeleteOutlined,
  PicCenterOutlined,
  PlusCircleOutlined,
  CheckCircleFilled,
  EyeOutlined,
  CloseCircleFilled,
  SaveFilled,
  EditFilled,
  EyeFilled,
  DeleteFilled,
  PlayCircleFilled,
  PlusCircleFilled,
} from "@ant-design/icons";
import {
  Modal,
  Row,
  Col,
  DatePicker,
  Button,
  Card,
  Empty,
  Form,
  Input,
  Popconfirm,
  Space,
  Table,
  message,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import axios from "axios";
import { useTranslation } from "react-i18next";
import { CompanyContext } from "../contexts/CompanyContext";
import { JSON_API } from "../services/Constants";
import { useAbility } from "../contexts/abilityContext";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);

const AssignmentTracking = () => {
  const { ability } = useAbility();
  let { t } = useTranslation();
  const { Company } = useContext(CompanyContext);
  const current = new Date();
  const [year, setYear] = useState(null);
  const [dataSource, setDataSource] = useState();
  const [messageApi, contextHolder] = message.useMessage();
  const [editingRow, setEditingRow] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedName, setSelectedName] = useState(null);
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(() => {
    return Number(localStorage.getItem("currentPageAssignement")) || 1;
  });
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    localStorage.setItem("currentPageAssignement", pagination.current);
  };

  var date;
  useEffect(() => {
    date = new Date().getFullYear();
    if (year == null) {
      setYear(date);
    }
    console.log("year" + date);
  }, [Company.id]);

  useEffect(() => {
    getassignement();
  }, [year]);

  const getassignement = async () => {
    if (year) {
      try {
        const response = await axios.get(
          `${JSON_API}/MonthAssignmentTrackings/enterprise/${Company.id}?year=${year}`
        );
        setDataSource(response.data);
      } catch (error) {
        setDataSource(null);
      }
    }
  };

  const showNameModal = (name) => {
    setSelectedName(name);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedName(null);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${JSON_API}/MonthAssignmentTrackings/${id}`);
      message.success(t("assignmentdeleted"));
      getassignement();
    } catch (error) {
      message.error(t("ErrorWhileDeletingassignment"));
    }
  };

  function getMonthName(index) {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Check if the index is valid
    if (index < 0 || index > months.length) {
      return "Invalid Month";
    }

    return months[index - 1];
  }
  const defaultColumns = [
    {
      title: `${t("Name")}`,
      dataIndex: "name",
      width: "20%",
      align: "center",
      render: (text, record) => {
        if (editingRow === record.id) {
          return (
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: `${t("assignmentnamerequired")}`,
                },
              ]}
            >
              <Input style={{ minWidth: "200px", maxWidth: "200px" }} />
            </Form.Item>
          );
        } else {
          return (
            <div
              style={{
                textAlign: "center",
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                cursor: "pointer",
              }}
              onClick={() => showNameModal(text)}
            >
              {text}
            </div>
          );
        }
      },
    },
    {
      title: `${t("creationdate")}`,
      dataIndex: "createdAt",
      align: "center",
      sorter: (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {dayjs(record.createdAt).format("DD/MM/YYYY")}
        </div>
      ),
    },
    {
      title: `${t("Year")}`,
      dataIndex: "year",
      align: "center",
      sorter: (a, b) => a.year - b.year,
      sortDirections: ["descend", "ascend"],
      render: (_, record) => (
        <div style={{ textAlign: "center" }}>{record.year}</div>
      ),
    },
    {
      title: `${t("Month")}`,
      dataIndex: "month",
      align: "center",

      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {t(getMonthName(record.month))}
        </div>
      ),
    },
    {
      title: `${t("billedhours")}`,
      dataIndex: "billedhours",
      align: "center",

      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {record.totalBilledHours ? record.totalBilledHours : 0} h
        </div>
      ),
    },
    {
      title: `${t("availablehours")}`,
      dataIndex: "availablehours",
      align: "center",

      render: (_, record) => (
        <div style={{ textAlign: "center" }}>
          {record.totalAvailableHours ? record.totalAvailableHours : 0} h
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "operation",
      align: "center",

      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Space size="middle">
            {editingRow === record.id ? (
              <>
                <Button type="link" danger onClick={() => setEditingRow(null)}>
                  <CloseCircleFilled /> {t("cancel")}
                </Button>
                <Button type="link" htmlType="submit">
                  <SaveFilled /> {t("save")}
                </Button>
              </>
            ) : (
              <>
                {ability.can("update", "Assignment Tracking") && (
                  <Popconfirm
                    type="link"
                    onClick={() => {
                      setEditingRow(record.id);
                      form.setFieldsValue({
                        name: record.name,
                      });
                    }}
                  >
                    <Button type="link" icon={<EditFilled />}>
                      {t("edit")}
                    </Button>
                  </Popconfirm>
                )}
                {ability.can("delete", "Assignment Tracking") && (
                  <Popconfirm
                    title={t("deleterow")}
                    onConfirm={() => handleDelete(record.id)}
                    cancelButtonProps={{
                      danger: true,
                      type: "primary",
                    }}
                    okText={
                      <>
                        <CheckCircleFilled /> {t("Delete")}
                      </>
                    }
                    cancelText={
                      <>
                        <CloseCircleFilled /> {t("Cancel")}
                      </>
                    }
                  >
                    <Button type="link" danger icon={<DeleteFilled />}>
                      {t("delete")}
                    </Button>
                  </Popconfirm>
                )}

                <Link
                  to={{
                    pathname: `/AssignmentTrackingDetails/${record.id}`,
                    state: { stateParam: record.id },
                  }}
                >
                  <Button type="link" icon={<EyeFilled />}>
                    {t("details")}
                  </Button>
                </Link>
              </>
            )}
          </Space>
        ) : null,
    },
  ];

  const onFinish = async (values) => {
    try {
      const obj = {
        name: values.name,
        enterpriseId: Company.id,
        month: values.year.format("MM"),
        year: values.year.format("YYYY"),
      };
      await axios.post(`${JSON_API}/MonthAssignmentTrackings`, obj);
      getassignement();
      message.success(t("assignmentcreation"));
    } catch (error) {
      message.error(t("assignmentcreationerror"));
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onFinishEdit = async (values) => {
    const obj = {
      id: editingRow,
      name: values.name,
    };
    await axios
      .put(`${JSON_API}/MonthAssignmentTrackings`, obj)
      .then((response) => {
        getassignement();
        message.success(t("assignmentupdate"));
      });
    setEditingRow(null);
  };

  const Duplicate = async () => {
    if (!year) {
      console.error("No year selected");
      message.warning(t("NoSelectedyear"));
      return;
    }
    await axios
      .post(`${JSON_API}/MonthAssignmentTrackings/duplicate/${Company.id}?year=${year}&name=${selectedName||''}`)
      .then((response) => {
        getassignement();
        console.log("assignment added Successfully!");
        message.success(t("assignmentcreation"));
      })
      .catch(function (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }

        console.log(error.config);
      });
  };

  const onChangee = (date, dateString) => {
    console.log(date, dateString);
    setYear(dateString);
  };

  return (
    <>
      <Card
        bordered={false}
        className="header-solid mb-24 stylish-card"
        title={<h3 className="font-semibold">{t("AssignmentTracking")}</h3>}
        extra={
          <DatePicker
            defaultValue={dayjs(date)}
            name="year"
            picker="year"
            placeholder={t("Selectyear")}
            className="stylish-datepicker"
            onChange={onChangee}
          />
        }
      >
        {ability.can("create", "Assignment Tracking") && (
          <Form
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            className="stylish-form"
          >
            <Row align="middle" gutter={[16, 16]}>
              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  label={t("Name")}
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: `${t("assignmentnamerequired")}`,
                    },
                  ]}
                >
                  <Input placeholder={t("Entername")} value={selectedName} onChange={e=>setSelectedName(e.target.value)}/>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={6}>
                <Form.Item
                  name="year"
                  label={t("Date")}
                  rules={[
                    {
                      required: true,
                      message: `${t("selectdate")}`,
                    },
                  ]}
                >
                  <DatePicker
                    placeholder={t("SelectDate")}
                    style={{ width: "100%" }}
                    picker="month"
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={3}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    icon={<PlusCircleFilled />}
                  >
                    {t("create")}
                  </Button>
                </Form.Item>
              </Col>

              <Col xs={24} sm={12} md={9}>
                <Form.Item>
                  <Button
                    block
                    onClick={Duplicate}
                    icon={<PicCenterOutlined />}
                  >
                    {t("duplicatelastassignment")}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}

        <Form
          form={form}
          onFinish={onFinishEdit}
          className="stylish-table-form"
        >
          <div style={{ overflowX: "auto", backgroundColor: "white" }}>
            <Table
              locale={{ emptyText: <Empty description={t("nodata")} /> }}
              bordered
              dataSource={dataSource}
              columns={defaultColumns}
              pagination={{ current: currentPage }}
              onChange={handleTableChange}
              className="stylish-table"
            />
          </div>
        </Form>
        <Modal
          title={t("NameofTheassignment")}
          visible={isModalVisible}
          onOk={closeModal}
          onCancel={closeModal}
        >
          <p>{selectedName}</p>
        </Modal>
      </Card>
    </>
  );
};
AssignmentTracking.acl = {
  subject: "Assignment Tracking",
  action: "read",
};
export default AssignmentTracking;
